body {
    color: #152d17;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
}

h1, h2 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 400;
    text-transform: uppercase;

    small {
        font-size: 14px;
        text-transform: none;
    }
}

h1 {
    margin-bottom: 55px;
    color: #018101;
}

h3 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
}

a {
    color: #152d17;
}