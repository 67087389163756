.ui-datepicker {
    width: auto !important;
    background: #f4f4f4;
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);

    .ui-state-hover {
        background: transparent;
        border: 0;
    }

    .ui-state-highlight {
        color: #de850a;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        cursor: pointer;

        .ui-icon {
            height: 22px;
            background: none;

            &:before {
                position: absolute;
                top: 0;
                font-family: FontAwesome, sans-serif;
                font-weight: bold;
                font-size: 20px;
                text-indent: 0;
            }
        }
    }

    .ui-datepicker-prev .ui-icon:before {
        content: '\f104';
        left: 0;
    }

    .ui-datepicker-next .ui-icon:before {
        content: '\f105';
        right: 0;
    }

    .ui-datepicker-prev-hover {
        left: 2px;
        top: 2px;
    }

    .ui-datepicker-next-hover {
        right: 2px;
        top: 2px;
    }

    .ui-datepicker-header {
        background: transparent;
        border: 0;
    }

    td {
        padding: 10px;
    }

    td .ui-state-default {
        background: transparent;
        border: 0;
        text-align: center;
    }

    a:hover {
        color: #de850a;
    }

    td .ui-state-active {
        position: relative;

        &:hover {
            color: white;
        }

        &:before {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            display: block;
            width: 40px;
            height: 40px;
            margin: -7px 0 0 -10px;
            background: black;
            border-radius: 50%;
        }
    }
}