.time-picker {
    position: absolute;
    display: none;
    width: 328px;
    background: white;
    box-shadow: 0 0 20px rgba(0,0,0,0.5);

    .time-val {
        position: relative;
        height: 78px;
        margin-bottom: 32px;
        background: #152d17;
        color: rgba(255,255,255,0.7);

        .val {
            position: absolute;
            right: 72px;
            top: 0;
            bottom: 0;
            line-height: 78px;
            font-size: 60px;
        }

        .time-val-am,
        .time-val-pm {
            position: absolute;
            right: 44px;

            &.active {
                color: white;
            }
        }

        .time-val-am {
            top: 15px;
        }

        .time-val-pm {
            bottom: 15px;
        }
    }

    .am-btn,
    .pm-btn {
        position: absolute;
        bottom: 15px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        background: white;
        border: 1px solid white;
        border-radius: 50%;
        text-align: center;
        text-decoration: none;

        &.active {
            border-color: black;
        }
    }

    .am-btn {
        left: 15px;
    }

    .pm-btn {
        right: 15px;
    }

    .clock {
        position: relative;
        width: 296px;
        height: 296px;
        margin: 0 auto 60px;
        background: #eee;
        border-radius: 50%;
    }

    .num {
        position: absolute;
        display: block;
        text-decoration: none;

        span {
            position: relative;
            z-index: 10;
        }
    }

    .num--active {
        color: white;

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            display: block;
            width: 30px;
            height: 30px;
            margin: -15px 0 0 -15px;
            background: black;
            border-radius: 50%;
        }
    }

    .num-1 {
        left: 50%;
        top: 34px;
        margin-left: 57px;
    }

    .num-2 {
        left: 50%;
        top: 79px;
        margin-left: 103px;
    }

    .num-3 {
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .num-4 {
        left: 50%;
        bottom: 79px;
        margin-left: 103px;
    }

    .num-5 {
        left: 50%;
        bottom: 34px;
        margin-left: 57px;
    }

    .num-6 {
        left: 50%;
        bottom: 15px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .num-7 {
        right: 50%;
        bottom: 34px;
        margin-right: 57px;
    }

    .num-8 {
        right: 50%;
        bottom: 79px;
        margin-right: 103px;
    }

    .num-9 {
        left: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .num-10 {
        right: 50%;
        top: 79px;
        margin-right: 103px;
    }

    .num-11 {
        right: 50%;
        top: 34px;
        margin-right: 57px;
    }

    .num-12 {
        left: 50%;
        top: 15px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .line {
        position: absolute;
        left: 50%;
        top: 50%;
        display: none;
        width: 120px;
        height: 2px;
        background: black;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        transition: 0.2s;
    }

    .line--show {
        display: block;
    }

    .hour-1 {
        -webkit-transform: rotate(-60deg);
    }

    .hour-2 {
        -webkit-transform: rotate(-30deg);
    }

    .hour-3 {
        -webkit-transform: rotate(0deg);
    }

    .hour-4 {
        -webkit-transform: rotate(30deg);
    }

    .hour-5 {
        -webkit-transform: rotate(60deg);
    }

    .hour-6 {
        -webkit-transform: rotate(90deg);
    }

    .hour-7 {
        -webkit-transform: rotate(120deg);
    }

    .hour-8 {
        -webkit-transform: rotate(150deg);
    }

    .hour-9 {
        -webkit-transform: rotate(180deg);
    }

    .hour-10 {
        -webkit-transform: rotate(205deg);
    }

    .hour-11 {
        -webkit-transform: rotate(235deg);
    }

    .hour-12 {
        -webkit-transform: rotate(-90deg);
    }
}

.time-picker--show {
    display: block;
}