body {
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, p, ul, ol {
    margin-top: 0;
    margin-bottom: 20px;
}

th, td {
    text-align: left;
    vertical-align: top;
}

input, textarea, select, button, th, td, .ui-widget {
    font-family: inherit;
    font-size: inherit;
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
}