form {
    position: relative;
}

form.login {
    position: relative;
    height: 100vh;
    background: url(../images/login-bg.jpeg) no-repeat center top;
    background-size: cover;
    color: white;
    text-align: center;

    .inner {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 360px;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .logo, h3 {
        text-align: center;
    }

    .text-input {
        background: white;
        border: 0;
    }

    .forgot-password {
        padding: 0 20px;
        text-align: left;

        a {
            color: white;
        }
    }
}

form p:after {
    content: '';
    display: table;
    clear: both;
}

.form-autosave-state {
    position: absolute;
    right: 40px;
    top: 64px;
    display: none;
    color: #de850a;

    .fa {
        margin-left: 0.5em;
    }

    &.show {
        display: block;
    }
}

.button {
    display: inline-block;
    height: 44px;
    line-height: 44px;
    padding: 0 30px;
    background: #018101;
    color: white;
    border: 0;
    border-radius: 3px;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    box-sizing: border-box;
    transition: 0.3s;

    &:hover {
        background: #152d17;
    }
}

.label {
    display: block;
}

form h3 {
    clear: both;
}

form p {
    clear: both;
}

form .field-half {
    float: left;
    clear: none;
    width: 278px;
}

form .field-half--omega {
    float: right;
}

form .field-half--clear {
    clear: both;
}

.button--block {
    width: 100%;
    display: block;
}

.button--back {
    position: relative;
    display: block;
    background: #f4f4f4;
    color: #152d17;
    text-align: left;

    &:before {
        content: '\f060';
        margin-right: 15px;
        font-family: FontAwesome, sans-serif;
    }

    &:hover {
        background: #f4f4f4;
    }
}

.button--black {
    background: white;
    color: #152d17;
    border: 2px solid #152d17;

    &:hover {
        background: #152d17;
        color: white;
    }
}

.button--green {
    background: #417505;
}

.button--lft {
    float: left;
}

.button--rgt {
    float: right;
    margin-left: 20px;
}

.button--lft,
.button--rgt {
    margin-top: 20px;
}

.button--square {
    width: 44px;
    padding: 0;
}

.label--left {
    display: inline-block;
    width: 56px;
}

.label--fleft {
    display: inline-block;
}

.label--sleft {
    float: left;
    width: 105px;
    line-height: 44px;
}

.custom-select__label,
.custom-select__options,
.dropdown,
.text-input,
.textarea {
    width: 100%;
    border: 1px solid #152d17;
    border-radius: 3px;
    box-sizing: border-box;
    outline: none;

    &:focus {
        box-shadow: 0 0 8px rgba(0,0,0,0.2);
    }

    &[readonly], &[disabled] {
        background: #f4f4f4;
    }
}

.custom-select {
    position: relative;
    display: inline-block;

    &:hover {
        background: white;

        .custom-select__options {
            background: white;
        }
    }
}

.main__sidebar .block .custom-select {
    display: block;
}

.main__sidebar .block #report_range {
    display: inline-block;
}

#range_type + .custom-select {
    display: inline-block;
    width: 208px;
}

.custom-select--small {
    width: 151px;
    margin-left: 19px;
}

.custom-select__label {
    position: relative;
    height: 44px;
    line-height: 44px;
    padding: 0 45px 0 15px;
    cursor: pointer;

    &:after {
        content: '\f107';
        position: absolute;
        right: 15px;
        top: 50%;
        font-family: FontAwesome, sans-serif;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

.custom-select__options {
    position: absolute;
    z-index: 50;
    left: 0;
    right: 0;
    top: 100%;
    display: none;
    overflow: auto;
    max-height: 200px;
    margin: -5px 0 0;
    padding: 10px 0 0;
    background: white;
    list-style-type: none;
    border-width: 0 1px 1px;
    border-radius: 0 0 3px 3px;

    &:before {
        content: '';
        position: absolute;
        left: 15px;
        right: 15px;
        top: 3px;
        display: block;
        height: 1px;
        background: #d8d8d8;
    }
}

.block .custom-select__options {
    background: #f4f4f4;
}

.custom-select--open .custom-select__options {
    display: block;
}

.custom-select__option {

    a {
        display: block;
        height: 35px;
        line-height: 35px;
        padding: 0 15px;
        text-decoration: none;
        white-space: nowrap;
    }
}

.dropdown {
    height: 44px;
    padding: 0 15px;
    background: transparent;
    cursor: pointer;
}

.dropdown--multiple {
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
}

.multiple-dropdown-list {
    margin: 0 0 20px;
    padding: 0;
    list-style-type: none;

    li {
        margin-bottom: 7px;
        padding-bottom: 7px;
        border-bottom: 1px solid #d8d8d8;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
        }
    }

    a.remove {
        visibility: hidden;
        color: #d0021b;
        font-size: 12px;
    }

    li:hover a.remove {
        visibility: visible;
    }
}

.text-input {
    height: 44px;
    padding: 0 20px;
    background: transparent;
}

.textarea {
    height: 270px;
    padding: 10px 20px;
    background: transparent;
    box-sizing: border-box;
}

.text-input--medium {
    width: 278px;
    margin-right: 19px;

    &:last-child {
        margin-right: 0;
    }
}

.dropdown--small,
.text-input--small {
    margin-left: 19px;
    width: 151px;
}

.text-input--small-no-margin {
    margin-left: 0;
}

.dropdown--xsmall,
.text-input--xsmall {
    margin-left: 10px;
    width: 105px;
}

.date-input, .time-input {
    position: relative;
    display: inline-block;

    input {
        padding-right: 30px;
    }

    &:after {
        position: absolute;
        right: 15px;
        top: 10px;
        font-family: FontAwesome, sans-serif;
    }
}

.date-input:after {
    content: '\f073';
}

.time-input:after {
    content: '\f017';
}

.multi-dropdowns {

    .custom-select--small {
        float: left;
        width: 145px;
        margin-left: 0;
        margin-right: 19px;
    }

    span:last-child .custom-select--small {
        margin-right: 0;
    }
}

.js-password-wrapper {
    position: relative;
    display: inline-block;

    .text-input--medium {
        margin-right: 0;
    }

    .plaintext {
        display: none;
    }

    .toggler {
        position: absolute;
        right: 10px;
        top: 50%;
        cursor: pointer;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);

        &:before {
            content: '\f070';
            font-family: FontAwesome, sans-serif;
        }
    }

    &.display {

        .js-password {
            display: none;
        }

        .plaintext {
            display: inline;
        }

        .toggler:before {
            content: '\f06e';
        }
    }
}