.main {
    position: relative;
    width: 1004px;
    margin: 0 auto;
    padding: 64px 40px 1px;

    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

.main--report {
    width: 578px;
}

.main__sidebar {
    float: left;
    width: 320px;

    .block {
        margin-bottom: 20px;
        padding: 15px 20px 1px;
        background: #f4f4f4;
    }
}

.main__content {
    float: right;
    width: 579px;
}

.main__content--thin {
    width: 578px;
}