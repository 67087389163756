hr {
    margin: 0 0 30px;
    padding: 0;
    height: 1px;
    background: #d8d8d8;
    color: #d8d8d8;
    border: 0;
}

.in-progress-state,
.completed-state {
    font-size: 12px;
    line-height: 22px;
}

.in-progress-state {
    color: #de850a;
}

.completed-state {
    color: #417505;
}

.js-modal {
    position: fixed;
    z-index: 200;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(21,45,23,0.5);

    .bubble,
    .content {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }

    .bubble {
        width: 594px;
        height: 594px;
        background: white;
        border-radius: 50%;
    }

    .content {
        width: 360px;
        text-align: center;

        p {
            color: #9b9b9b;
        }
    }
}

.highlight {
    padding: 10px 15px;
    background: #f4f4f4;
    border: 1px solid #152d17;
    border-radius: 3px;
}

.link-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        border-bottom: 1px solid #d8d8d8;

        &:last-child {
            border-bottom: 0;
        }
    }

    strong {
        color: #152d17;
        font-size: 18px;
        font-weight: 400;
    }

    a {
        position: relative;
        display: block;
        padding: 10px 30px 10px 10px;
        color: #9b9b9b;
        text-decoration: none;
        transition: 0.3s;

        &:after {
            content: '\f0a9';
            position: absolute;
            right: 10px;
            top: 50%;
            font-family: 'FontAwesome', sans-serif;
            font-size: 18px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            transition: 0.3s;
        }

        &:hover {
            background: #f4f4f4;

            &:after {
                color: #018101;
            }
        }
    }
}

.success-screen {
    position: relative;
    min-height: 100vh;
    background: #a4a4a4;
}

.success-screen__bubble {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 596px;
    height: 596px;
    background: white;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.success-screen__inner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 360px;
    height: 360px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.choose-links {
    text-align: center;

    h2 {
        color: #008000;
    }
}

.choose-links__links {
    margin: 0 0 20px;
    padding: 85px 0 0;
    list-style-type: none;

    li {
        float: left;
        width: 33%;
        //padding: 0 30px;
        box-sizing: border-box;
    }

    a {
        position: relative;
        display: block;
        margin: 0 auto;
        width: 239px;
        height: 239px;
        text-decoration: none;
        border: 1px solid black;
        border-radius: 50%;
        transition: 0.2s;
        box-sizing: border-box;

        &:hover {
            background: #f4f4f4;
            border: 4px solid #018101;
        }
    }

    span {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        font-size: 18px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

.tabbed-content {
    padding: 0;
    border: 0 !important;

    .ui-widget-header {
        background: transparent;
        border-width: 0 0 1px;
        border-style: solid;
        border-color: #d8d8d8;
    }

    .ui-tabs-nav {
        padding: 0;

        li {
            margin: 0 20px -1px 0;
            padding: 0 0 1px;
            background: #d8d8d8;
            border-color: #d8d8d8;
            border-bottom-width: 1px;

            .ui-tabs-anchor {
                height: 40px;
                line-height: 40px;
                padding: 0 40px;
                background: #d8d8d8;
                color: #9b9b9b;
                border: 0;
                font-size: 18px;
                outline: none;
            }
        }

        li.ui-tabs-active {
            background: white;
            border-bottom-color: white;

            .ui-tabs-anchor {
                background: white;
                color: #152d17;
                border-color: transparent;
            }
        }
    }

    .ui-tabs-panel {
        padding: 30px 0 0;
    }
}

.js-duplicate {
    position: relative;

    .js-duplicate-child {
        position: relative;
        padding-right: 85px;
    }

    .js-duplicate-add,
    .js-duplicate-remove {
        position: absolute;
        right: 0;
        bottom: 0;
        display: inline-block;
        padding-left: 20px;
        text-decoration: none;
        font-size: 12px;
        line-height: 20px;

        .fa {
            position: absolute;
            left: 0;
            top: 0;
            font-size: 20px;
            line-height: 20px;
        }
    }

    .js-duplicate-add {
        z-index: 10;

        .fa {
            color: #018101;
        }
    }

    .js-duplicate-remove {
        bottom: auto;
        top: 0;

        .fa {
            color: #D0021B;
        }
    }
}

.js-duplicate--empty {
    height: 50px;
}

table.data {
    width: 100%;
    margin: 0 0 20px;
    padding: 0;
    border: 0;
    border-collapse: collapse;

    tr {

        td {
            padding: 10px 15px;
            background: #f4f4f4;
        }

        &:nth-child(even){

            td {
                background: transparent;
            }
        }
    }
}

.js-multiple-advanced {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #152d17;
    border-radius: 3px;

    .toggler {
        display: none;
    }

    .toggle {
        position: relative;
        display: block;
        cursor: pointer;

        &:after {
            content: '\f107';
            position: absolute;
            right: 0;
            top: 50%;
            font-family: FontAwesome, sans-serif;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    .toggler:checked ~ .toggle {
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid #d8d8d8;

        &:after {
            margin-top: -3px;
        }
    }

    .form {
        display: none;
    }

    .toggler:checked ~ .form {
        display: block;
    }

    .search {
        position: relative;
        padding-right: 185px;

        .text-input {
            padding-right: 45px;
        }

        &:after {
            content: '\f002';
            position: absolute;
            right: 205px;
            top: 50%;
            font-family: FontAwesome, sans-serif;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    .togglers {
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            float: left;
            width: 33%;
            display: block;
        }

        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }
}