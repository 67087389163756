.header {
    border-bottom: 1px solid #d8d8d8;
}

.header__inner {
    position: relative;
    width: 1004px;
    height: 85px;
    margin: 0 auto;
}

.header__inner--report {
    width: 578px;
}

.header__close-link {
    position: absolute;
    right: 0;
    top: 50%;
    display: block;
    width: 29px;
    height: 29px;
    line-height: 29px;
    color: #d0021b;
    border: 1px solid #d0021b;
    border-radius: 50%;
    text-align: center;
    -webkit-transform: translate3d(0,-50%,0);
    transform: translate3d(0,-50%,0);
}

.header__logo {
    position: absolute;
    left: 20px;
    top: 23px;
}

.header__nav {
    position: absolute;
    right: 0;
    top: 0;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    li, a, .toggler {
        float: left;
        height: 85px;
        line-height: 85px;
    }

    a {
        padding: 0 15px;
        text-decoration: none;
        transition: 0.3s;
    }

    .toggler {
        width: 104px;
        text-align: center;
        font-size: 20px;
        cursor: pointer;
    }

    .active a,
    a:hover {
        background: #f4f4f4;
    }

    .tools {
        position: relative;
        z-index: 20;

        &:hover {
            background: #f4f4f4;
        }

        ul {
            position: absolute;
            right: 0;
            top: 100%;
            display: none;
            border-top: 1px solid #d8d8d8;
            background: #f4f4f4;

            li, a {
                float: none;
                height: 49px;
                line-height: 49px;
            }

            a {
                position: relative;
                display: block;
                white-space: nowrap;
                padding: 0 25px 0 50px;
            }

            .fa {
                position: absolute;
                left: 25px;
                top: 50%;
                font-size: 20px;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }

        &:hover ul {
            display: block;
        }
    }
}